/**
 * 404 page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Ctabutton from "../components/ctabutton"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Sectionctafooter from "../components/sectionctafooter"
import Sectionh1content from "../components/sectionh1content"
import Seo from "../components/seo"
import "../styles/pages/404.scss"


/* Page declaration */
const NotFoundPage = ({ data }) => {
  //Const data ACF
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPage404

  //CTA Footer page FAQ
  var ctaFooterFAQ= data.options.acfPageOptions.repeaterCtaFooter.filter(function (ctaFooter) {
    return ctaFooter.typeCtaFooter === "faq"
  })
 return (
    <Layout className="notfound">
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
      />

      {/* Section Hero */}
      <Hero colorShape="marine" classNamePage="notfound" />

      {/* Section H1 */}
      <Sectionh1content titleH1={dataAcf.titleGeneral404} />

      {/* Main content */}
      <section className="section-404-not-found main-section marine">
        <div className="content-general" >
          <div dangerouslySetInnerHTML={{ __html:dataAcf.contentGeneral404}} />
          <p className="big-text">404</p>
          <Ctabutton ctaLabel="Go to Homepage" ctaLink="/" />
        </div>
      </section>

      {/* Section CtaFooter */}
      {ctaFooterFAQ.map(function (ctafooter, i) {
        return <Sectionctafooter key={i} typeCta="faq" titleCta={ctafooter.titleCtaFooter} labelCta={ctafooter.labelCtaFooter} classCta="marine" />
      })}
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "404-not-found"}) {
      acfPage404 {
        titleGeneral404
        contentGeneral404
      }
      seo {
        metaDesc
        title
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterCtaFooter {
          typeCtaFooter
          titleCtaFooter
          labelCtaFooter
        }
      }
    }
  }
`

/* Export page informations */
export default NotFoundPage
