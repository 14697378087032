/**
 * Const with params element
 * Section h1 content component
 * Display a section with H1 and paragraph
 */
/* Import section */
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Shapeh1 from "../images/shapes/shapeh1.inline.svg"
import "../styles/components/sectionh1content.scss"

/* Declaration function */
const Sectionh1content = ({ titleH1, contentH1, classNameH1, classNameParent, children, vc }) => {
  return (
    <section className={ `section-h1 ${ classNameH1 }` }>
      <div className="box-section">
        {classNameH1 === "virtualcare blue-vc" ? (
          <div className="title-virtualcare">
            <StaticImage  src="../images/icons/virtual_care_blue_icon.png" alt="Icon leaf blue Virtual Care PortalConnect" layout="fixed" width={36} quality={100} />
            <h1 className="title-level-1">{ titleH1 }</h1>
          </div>
        ) : (
          <h1 className="title-level-1">{ titleH1 }</h1>
        )}
        {contentH1 && <div className={ `parent-content ${ classNameParent }` } dangerouslySetInnerHTML={{ __html:contentH1}} />}
        {children && <div className="children-content">{children}</div>}
      </div>
      {!vc &&
        <div className="shape-h1">
          <Shapeh1 />
        </div>
      }
    </section>
  )
}


/* Export function */
export default Sectionh1content
