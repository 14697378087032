/**
 * Const with params element
 * Image hero component
 * Display an image in hero of each page
 */
/* Import section */
import { StaticImage } from "gatsby-plugin-image"
import React, { Component } from "react"
import Ctabutton from "./ctabutton"
import "../styles/components/hero.scss"

/* Declaration function */
class Hero extends Component  {

  constructor(props) {
    super(props)
    this.state = {
      width: 0
    }
  }

  componentDidMount() {
    this.handleWindowSizeChange()
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    if(window.innerWidth !== this.state.width) {
      this.setState({
        width: window.innerWidth
      })
    }
  }

  render () {
    var classNamePage = this.props.classNamePage
    var colorHero = this.props.colorHero
    var colorShape= this.props.colorShape
    var vcPromo= this.props.vcPromo
    var vcPlans = this.props.vcPlans

    return (
      <section className={`section-hero ${colorHero}`}>
        <div className={`background-hero ${classNamePage}`}></div>
        <div className={`shape-hero ${colorShape}`}></div>
        {/* {vcPromo &&
          <div className="box-promo">
            <div className="shape-promo">
              <StaticImage  src="../images/icons/virtual_care_icon.png" alt="Icon leaf Virtual Care PortalConnect" layout="fixed" width={45} />
            </div>
            <p>{vcPromo}</p>
            {vcPlans ? (
              <Ctabutton ctaLabel="See Our Plans" ctaLink="/virtual-care/plans" otherStyle="white-blue-format seeplans-hero" />
            ) : (
              <Ctabutton ctaLabel="Sign Up Here" ctaLink="/virtual-care/sign-up" otherStyle="white-blue-format signup-hero" />
            )}
          </div>
        } */}
      </section>
    )
  }
}

/* Export function */
export default Hero
